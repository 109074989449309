import React, { Component } from "react";

import TitleHeader from "../components/TitleHeader/TitleHeader";
import HeaderImage from "../img/services.jpg";

const Heading = "Services";

class Services extends Component {
  render() {
    return (
      <div>
        <TitleHeader image={HeaderImage} heading={Heading} />
        <div className="container py-10">
          <h4>Support</h4>
          <p>
            Our technicians are well trained and tend to excel when taking on
            the after sales support and maintenance of new partner products. We
            dedicate our time and energy into pursuing partnerships with the
            best brands and that includes taking on the after sales support. We
            train our technicians in every aspect of after sales care, support
            and maintenance to provide our clients peace of mind when purchasing
            a new product.
          </p>
          <h4>After Sales</h4>
          <p>
            Most companies sell products and leave their clients in the dark
            with parts and spares. Our experience has taught us that finding
            genuine, OEM parts are essential in ensuring your equipment performs
            well under the right conditions. OEM parts also ensure longevity
            which is why we provide the after sales support to accompany our
            partner products.
          </p>
        </div>
      </div>
    );
  }
}

export default Services;
